import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ervin from "../img/ervin-hiking.jpg"
import sooKids from "../img/soo-kids.jpg"
import { Helmet } from "react-helmet"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Ervin Ebalo</title>
      <link rel="canonical" href="https://ervinebalo.com" />
    </Helmet>

    <h3>Passion 1: Kids + Education!</h3>
    <p>
    My wife and I run a non-profit organization called the <a href="https://sumofone.org/">Sum of One</a>. Our mission is to help remove obstacles that make it difficult for children of need to get an education. Our main project right now is establishing in-school feeding programs. <a href="https://sumofone.org/projects/2019-20-feeding-program">Learn more about it here</a>.
    </p>
    <img src={sooKids} alt="Sum of One" className="dropshadow" />

    <h3>Passion 2: Building Products!</h3>
    <img src={ervin} alt="Ervin Ebalo - Hiking" className="dropshadow" />
    <p>
    Based in Orange County, CA, I lead a team of product managers that build software products while driving the roadmap of a tightly integrated product line of software, hardware, and firmware. I combine my technical background and business accumen to iteratively deliver value to users.
    </p>
    
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`